import React from 'react';
import { Link } from 'gatsby';
import Footer from './footer';
import avatar from '../assets/images/avatar.jpg';

class Header extends React.Component {
  render() {
    return (
      <header id="header">
        <div className="inner">
          <div className="image avatar">
            <Link to="/">
              <img src={avatar} alt="It's me - Justin Kwan Lee!" />
            </Link>
          </div>
          <h1>
            <strong>I am Justin Lee</strong>,<br /> a developer based out of
            Toronto.
          </h1>
          <br />
          <Link to="/about">About</Link>
          <br />
          <Link to="/archives">Archives</Link>
          <br />
          <Link to="/contact">Contact</Link>
        </div>
        <Footer />
      </header>
    );
  }
}

export default Header;
